import wechatjt from '@/assets/wechatjt.png';
import down_back from '@/assets/down_back.png';
import safari from '@/assets/safari.png';
import logo from '@/assets/jjgg/logo.png';
import douyin from '@/assets/jjgg/douyin.png';
import img1 from '@/assets/jjgg/img1.png';
import img2 from '@/assets/jjgg/img2.png';
import img3 from '@/assets/jjgg/img3.png';
import img4 from '@/assets/jjgg/img4.png';
import img5 from '@/assets/jjgg/img5.png';
import right from '@/assets/jjgg/right.png';
import left from '@/assets/jjgg/left.png';
import video from '@/assets/jjgg/video.gif';
import { ref } from 'vue';
import { downPageRecord } from '@/api'; // import { useRoute } from 'vue-router'

export default {
  name: 'DownPage',

  setup() {
    // const route = useRoute()
    const isWechat = ref(false);
    const show = ref(false);
    const isIos = ref(false);
    const isMobile = ref(false);
    const downs = ref(null);
    const margin = ref(0);
    const userAgent = navigator.userAgent.toLowerCase();
    const match = userAgent.match(/MicroMessenger/i);

    if (match !== null && match.toString() === 'micromessenger') {
      isWechat.value = true;
    }

    isIos.value = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

    const close = () => {
      show.value = false;
    };

    const topClick = () => {
      if (downs.value === null) {
        window.location.href = 'https://static.mianjuyuehui.net/gamedownload/jjgg/jjgg-channel-guanwang.apk';
        localStorage.setItem('jjgg', '1');
      }
    };

    isMobile.value = !!navigator.userAgent.match(/AppleWebKit.*Mobile.*/);

    if (isMobile.value) {
      document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    } else {
      document.getElementById('viewport').content = '';
    } //下载按钮点击时调用方法


    const down = e => {
      e.stopPropagation();
      window.location.href = 'https://static.mianjuyuehui.net/gamedownload/jjgg/jjgg-channel-guanwang.apk';
    };

    downs.value = localStorage.getItem('jjgg');

    if (downs.value === null) {
      setTimeout(() => {
        window.location.href = 'https://static.mianjuyuehui.net/gamedownload/jjgg/jjgg-channel-guanwang.apk';
      }, 2000);
    }

    const toLeft = e => {
      e.stopPropagation();

      if (margin.value < 0) {
        margin.value += 500;
      }
    };

    downPageRecord();

    const toRight = e => {
      e.stopPropagation();

      if (margin.value > -1000) {
        margin.value -= 500;
      }
    };

    return {
      down,
      wechatjt,
      isWechat,
      show,
      down_back,
      safari,
      isIos,
      close,
      logo,
      douyin,
      video,
      isMobile,
      img1,
      img2,
      img3,
      img4,
      img5,
      topClick,
      right,
      left,
      toLeft,
      toRight,
      margin
    };
  }

};