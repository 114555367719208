import { useRoute } from 'vue-router';
import { netNoticeInfo } from '@/api';
import { ref } from 'vue';
export default {
  name: 'NetNotice',

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    const info = ref({});
    const route = useRoute();

    if (route.params.id !== undefined && route.params.id !== '') {
      netNoticeInfo(route.params.id).then(res => {
        if (res.code === 0) {
          info.value = res.data;
        }
      });
    }

    return {
      info
    };
  }

};