import axios from 'axios'
import { showToast } from 'vant'
import store from '@/store'
import router from '@/router'

// import store from '@/store'
// import notice from '@/components/common/notice'
// import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000 // 请求超时时间
})

const escalation = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    // const data = error.response.data
    showToast('服务器发生错误，请稍后重试')
    // 从 localstorage 获取 token
    // const token = localStorage.getItem(ACCESS_TOKEN)
    // if (error.response.status === 403) {
    //   notice(data.message)
    // }
    // if (error.response.status === 400) {
    //   notice('400 Bad Request')
    // }
    // if (error.response.status === 401) {
    //   notice(data.message || 'Authorization verification failed')
    //   if (token) {
    //     store.dispatch('Logout').then(() => {
    //     })
    //   }
    //   router.push({name: 'LoginPage'})
    // }
    // if (error.response.status === 404) {
    //   notice('请求地址错误')
    // }
    // if (error.response.status === 500) {
    //   notice('服务器发生错误，请稍后重试')
    // }
  }
  return Promise.reject(error)
}
escalation.interceptors.request.use(config => {
  config.headers['page'] = 'jjgg'
  return config
}, error => Promise.reject(error))
// request interceptor
request.interceptors.request.use(config => {
  const token = store.getters.getToken
  // // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['x-access-token'] = token
    // config.headers['app-platform'] = 'web'
    // config.headers['app-version'] = '4.0'
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.code === 1) {
    showToast('登录已失效，请重新登录')
    store.dispatch('Logout').then(() => {
      router.push({ name: 'LoginGuide' })
    })
  } else if (response.data.code === 2) {
    showToast(response.data.message || '操作失败，请稍后重试')
  }
  return response.data
}, errorHandler)

export default request

export {
  request as axios,
  escalation
}
