import preventWord from '@/assets/prevention-word.png';
import preventIcon from '@/assets/prevention-back.png';
import preventLock from '@/assets/prevention-lock.png';
import { ref } from 'vue';
export default {
  name: 'PreventionGuide',

  setup() {
    const arr = ref([]);

    for (let i = 0; i < 390; i++) {
      arr.value.push(1);
    }

    return {
      preventWord,
      preventIcon,
      preventLock,
      arr
    };
  }

};