// @ is an alias to /src
import store from '@/store';
import { computed } from 'vue';
import { onMounted } from 'vue';
import qrcode from '@/assets/qrcode.png';
import rightimg from '@/assets/rightimg.png';
import word from '@/assets/word.png';
import ios from '@/assets/ios-black.png';
import android from '@/assets/android-black.png';
import beian from '@/assets/beian.png';
import quan from '@/assets/icon.png';
import ContactTip from '@/components/ContactTip';
import { ref } from 'vue';
import { saveAs } from 'file-saver';
export default {
  name: 'HomeView',
  components: {
    ContactTip
  },

  setup() {
    const user = computed(() => {
      return store.getters.getUserInfo;
    });
    onMounted(() => {
      const ua = navigator.userAgent;
      const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
            isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
            isAndroid = ua.match(/(Android)\s+([\d.]+)/);

      if (isIphone || isAndroid) {
        window.location.href = 'https://www.xqse.cn/down-page';
      }
    });

    const bigImg = () => {
      big.value = !big.value;
    };

    const downloadAndroid = () => {
      saveAs('https://www.xqse.cn/download/androidRelease.apk', 'xiaoquan.apk');
    };

    const downIos = () => {
      window.location.href = 'itms-services://?action=download-manifest&url=https://www.mianjuyuehui.net/download/manifest.plist';
    };

    const tips = ref();
    const big = ref(false);
    return {
      user,
      qrcode,
      rightimg,
      ios,
      android,
      tips,
      bigImg,
      big,
      downloadAndroid,
      quan,
      word,
      downIos,
      beian
    };
  }

};