import Back from '@/assets/invite/back.png';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { sendCash } from '@/api/user';
import { showToast } from 'vant';
import store from '@/store';
export default {
  name: 'CashPage',

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    const router = useRouter();
    const send = ref(false);
    const param = ref({
      account: '',
      name: ''
    });
    const info = computed(() => {
      return store.getters.invInfo;
    });

    const cash = () => {
      if (!send.value) {
        send.value = true;
        sendCash(param.value).then(res => {
          if (res.code === 0) {
            showToast('提交成功');
            store.dispatch('RefreshInv');
          }
        }).finally(() => setTimeout(() => send.value = false, 1000));
      }
    };

    return {
      Back,
      router,
      param,
      cash,
      info
    };
  }

};